import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  Typography,
  Stack,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  AppBar,
  Toolbar,
  FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Grid
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Choose the appropriate date adapter for your project

import { styled } from '@mui/material/styles';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { DatePicker,LocalizationProvider } from '@mui/lab';
import imgLogo from './carlogowhite.jpeg'; // Adjust the path as needed
import API_BASE_URL from './config'; // Import the configuration

import CenteredForm from './CenteredForm'; // Import the CenteredForm component

const stripePromise = loadStripe('pk_test_6MGGWIzRIzoNtgfxgMeyxPqR00sYE0jQIQ');

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
const Header = styled(AppBar)({
  backgroundColor: '#2196F3', // Customize the color
  marginBottom: '16px',
});
const HeaderTitle = styled(Typography)({
  flexGrow: 1,
});

const RadioLabel = styled(Typography)({
  marginBottom: '8px',
});

const SubmitButton = styled(Button)({
  marginTop: '16px',
});

ShopProductCardT.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCardT({ product }) {
const [Name, setName] = useState('');
const [Prenom, setPrenom] = useState('');
const [Email, setEmail] = useState('');
const [cardName, setCardName] = useState('');
const [cardCVV, setCardCVV] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rentalDate: null,
  });

 const steps = [
    'Car info',
    'Choisissez le kilométrage', // New step for "Choisissez le kilométrage" section
    'Choisissez votre protection',
    'Options',
    'Vérifier et réserver',
  ];  const [selectedKilometerOption, setSelectedKilometerOption] = useState(null); // To store the selected option
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState(null); // To store the selected option
  const [selectedOptionStep2, setSelectedOptionStep2] = useState(null); // To store the selected option for Step 2
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null); // Add this state
  const [cardNumber, setCardNumber] = useState(null); // Add this state
const [pickupDay, setPickupDay] = useState('');
const [pickupMonth, setPickupMonth] = useState('');
const [pickupYear, setPickupYear] = useState('');

const [returnDay, setReturnDay] = useState('');
const [returnMonth, setReturnMonth] = useState('');
const [returnYear, setReturnYear] = useState('');

function generateDayOptions() {

}

function generateMonthOptions() {

}
function generateYearOptions() {

}

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
const handlePayment = async (cardNumber, cardName) => {
  const stripe = await stripePromise;

  // Create a payment intent on your server
  const response = await fetch(`${API_BASE_URL}/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount: 1000 }), // Adjust the amount as needed
  });

  const { clientSecret } = await response.json();

  // Use Stripe.js to confirm the payment
  const result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: Elements.getElement(CardElement),
      billing_details: {
        name: cardName,
      },
    },
  });

  if (result.error) {
console.log("error")
 } else {
console.log("Payment ok")
  }
};

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append('id', 1211);
    formDataObj.append('subscriber_name', Name);
    formDataObj.append('email', Name);
    formDataObj.append('kilometrage', selectedKilometerOption);
    formDataObj.append('Assurance', selectedInsuranceOption);
    formDataObj.append('Options', selectedOptionStep2);
    formDataObj.append('start_date', Name);
    formDataObj.append('deleted_at', "-");
    formDataObj.append('active', true);
    formDataObj.append('cardNumber', cardNumber);

    console.log(formDataObj.get('cardNumber'))
    console.log(formDataObj.get('Options'))

    axios.post(`${API_BASE_URL}/rent`, formDataObj)
      .then((response) => {
        console.log('Product added successfully!');
        console.log(response.data);

      })
      .catch((error) => {
        console.log('Failed to add product:', error);
      });
      await handlePayment(cardNumber, cardName);

    // Reset form data and selected options if needed
    setFormData({
      name: '',
      email: '',
      subscriber_name: '',
      kilometrage: '',
      Assurance: '',
      Options: '',
      start_date: '',
      deleted_at: '',
      active: '',
      rentalDate: null,
    });

    setName('');
    setSelectedKilometerOption('null');
    setSelectedInsuranceOption('null');
    setSelectedOptionStep2('null');
    setCardNumber('null');
  };


  return (
    <Card style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
        {/* src={imgLogo} https://redacars.ma/wp-content/uploads/2022/01/LOGO-GRAND-FORMA-1-1.png */}

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
             src={imgLogo}
          alt="Logo"
          style={{ maxWidth: '20%', height: 'auto' }}
        />

          </Grid>
            <Grid item xs={12}>
              <CenteredForm onSubmit={handleFormSubmit}>
      <form onSubmit={handleFormSubmit}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

      <Stack spacing={2} sx={{ p: 3 }}>
              {activeStep === 0 && (
                <>
                  <TextField
                    label="Nom"
                    variant="outlined"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <TextField
                    label="Prenom"
                    variant="outlined"
                    value={Prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                    <TextField
                                      label="Email address"
                                      variant="outlined"
                                      value={Email}
                                      onChange={(e) => setEmail(e.target.value)}
                                 />
                                  {/* Associate label with year select
<div>
  <label htmlFor="pickupDay">Pickup Day:
  <select
    id="pickupDay"
    value={pickupDay}
    onChange={(e) => setPickupDay(e.target.value)}
  >
    {generateDayOptions()}
  </select></label>

  <label htmlFor="pickupMonth">Pickup Month:
  <select
    id="pickupMonth"
    value={pickupMonth}
    onChange={(e) => setPickupMonth(e.target.value)}
  >
    {generateMonthOptions()}
  </select></label>

  <label htmlFor="pickupYear">Pickup Year:
  <select
    id="pickupYear"
    value={pickupYear}
    onChange={(e) => setPickupYear(e.target.value)}
  >
    {generateYearOptions()}
  </select></label>
</div>

<div>
  <label htmlFor="returnDay">Return Day:
  <select
    id="returnDay"
    value={returnDay}
    onChange={(e) => setReturnDay(e.target.value)}
  >
    {generateDayOptions()}
  </select>
</label>
  <label htmlFor="returnMonth">Return Month:
  <select
    id="returnMonth"
    value={returnMonth}
    onChange={(e) => setReturnMonth(e.target.value)}
  >
    {generateMonthOptions()}
  </select>
</label>
  <label htmlFor="returnYear">Return Year:
  <select
    id="returnYear"
    value={returnYear}
    onChange={(e) => setReturnYear(e.target.value)}
  >
    {generateYearOptions()}
  </select>
  </label>
</div>  */}

                </>
              )}
   {activeStep === 1 && (
            <>
              {/* "Choisissez le kilométrage" section */}
              <Typography variant="h6">Choisissez le kilométrage</Typography>
                       <FormControl component="fieldset">
                         <RadioGroup
                           aria-label="kilometerOptions"
                           name="kilometerOptions"
                           value={selectedKilometerOption}
                           onChange={(e) => setSelectedKilometerOption(e.target.value)}
                         >
                           <FormControlLabel
                             value="Longue distance-Kilomètres illimités+20,52 €"
                             control={<Radio />}
                             label="Longue distance - Kilomètres illimités + 20,52 €"
                           />
                           <FormControlLabel
                             value="Distance moyenne-Conduisez jusqu'à 510 km + 0,14 € par km supplémentaire"
                             control={<Radio />}
                             label="Distance moyenne-Conduisez jusqu'à 510 km + 0,14 € par km supplémentaire"
                           />
                         </RadioGroup>
                       </FormControl>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Typography variant="h6">Assurance pertes et dommages</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="insuranceOptions"
                  name="insuranceOptions"
                  value={selectedInsuranceOption}
                  onChange={(e) => setSelectedInsuranceOption(e.target.value)}
                >
                  <FormControlLabel
                    value="9,96 € /jour"
                    control={<Radio />}
                    label="Frais de dépassement minimum - 3 00,00 € de responsabilité financière + protection pour le vol - 9,96 € /jour"
                  />
                  <FormControlLabel
                    value="5,53 € /jour"
                    control={<Radio />}
                    label="Frais de dépassement réduit - 7 00,00 € de responsabilité financière + protection pour le vol - 5,53 € /jour"
                  />
                  <FormControlLabel
                    value="Inclus"
                    control={<Radio />}
                    label="Frais de dépassement maximum - 14 00,00 € de franchise - Inclus"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {activeStep === 3 && (
            <>
              <Typography variant="h6">Choisissez vos options</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="optionsStep2"
                  name="optionsStep2"
                  value={selectedOptionStep2}
                  onChange={(e) => setSelectedOptionStep2(e.target.value)}
                >
                  <FormControlLabel
                    value="7,38 € /jour"
                    control={<Radio />}
                    label="Conducteur supplémentaire - Partagez le volant avec un autre conducteur - 7,38 € /jour"
                  />
               <FormControlLabel
                 value="6,64 € /jour"
                 control={<Radio />}
                 label={
                   <span>
                     Protection pneus et pare-brise - Zéro responsabilité financière pour les dégâts <br /> au pare-brise aux vitres et aux pneus
                     6,64 € /jour
                   </span>
                 }
               />
                </RadioGroup>
              </FormControl>
            </>
          )}
{activeStep === 4 && (
  <>
    <Typography variant="h6">Options de paiement</Typography>
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="paymentOptions"
        name="paymentOptions"
        value={selectedPaymentOption}
        onChange={(e) => setSelectedPaymentOption(e.target.value)}
      >

        <FormControlLabel
          value="option2"
          control={<Radio />}
          label="Économisez de l'argent en payant maintenant."
        />
        <FormControlLabel
          value="option3"
          control={<Radio />}
          label="Flexible Restez flexible en payant plus tard Payez au retrait"
        />
      </RadioGroup>
    </FormControl>

{selectedPaymentOption !== "option3" && (
      <>
    <Typography variant="h6">Informations sur le conducteur</Typography>
    <TextField
      label="Nom"
      variant="outlined"
      value={formData.driverName}
      onChange={(e) => setFormData({ ...formData, driverName: e.target.value })}
    />
    <TextField
      label="Nom de famille"
      variant="outlined"
      value={formData.driverLastName}
      onChange={(e) => setFormData({ ...formData, driverLastName: e.target.value })}
    />
    <TextField
      label="Email"
      variant="outlined"
      value={formData.driverEmail}
      onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })}
    />
    <TextField
      label="Numéro de téléphone"
      variant="outlined"
      value={formData.driverPhone}
      onChange={(e) => setFormData({ ...formData, driverPhone: e.target.value })}
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={formData.isOver24}
          onChange={(e) => setFormData({ ...formData, isOver24: e.target.checked })}
        />
      }
      label="Avez-vous plus de 23 ans?"
    />


    <Typography variant="h6">Paiement</Typography>
    <Elements stripe={stripePromise}> {/* Wrap the part containing CardElement */}
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
    </Elements>

</>
    )}
  </>
)}
          <Stack direction="row" justifyContent="space-between">
            {activeStep > 0 && (
              <Button variant="contained" color="secondary" onClick={handleBack}>
                Back
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
        <Elements stripe={stripePromise}>
          <SubmitButton type="submit" variant="contained" color="primary">
                           Payer maintenant
                         </SubmitButton>
        </Elements>
            )}
          </Stack>
        </Stack>
      </form>
      </CenteredForm>
 </Grid>
    </Grid>
    </Card>
  );
}
