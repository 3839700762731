import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import API_BASE_URL from './config'; // Import the configuration


// Function to fetch data from the external web service
async function fetchProducts() {
  const response = await axios.get(`${API_BASE_URL}/product/1`);
  return response.data; // Axios automatically parses the JSON data, no need for .json()
}

// ----------------------------------------------------------------------

export default function ProductList({ ...other }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch the products from the external web service
    fetchProducts()
      .then((data) => {
         if (Array.isArray(data)) {
           setProducts(data);
         } else {
           console.error('Data is not an array:', data);
         }
       })
      .catch((error) => console.error('Error fetching products:', error));
  }, []);
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}

ProductList.propTypes = {
  // No need to specify PropTypes.array.isRequired here as the products are fetched asynchronously
};