import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import translationAR from './translations/ar.json';
import translationFR from './translations/fr.json';
import translationEN from './translations/en.json';
import translationDE from './translations/de.json';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import ProtectedRoute from './ProtectedRoute';
import LoginPage from './pages/LoginPage';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      ar: { translation: translationAR },
      de: { translation: translationDE }
    },
    lng: 'en', // Set the default language here
    fallbackLng: 'en', // Fallback language if translation for the current language is not available
    interpolation: {
      escapeValue: false // React already escapes values, so no need to escape again
    }
  });

export default function App() {
  const isAuthenticated = localStorage.getItem('authToken') !== null;
  console.log('isAuthenticated:', isAuthenticated); // Add this line to log isAuthenticated

  return (
      <I18nextProvider i18n={i18n}>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <ProtectedRoute
          path="/"
          element={<DashboardLayout />}
          condition={isAuthenticated}
          redirectPath="/login"
        />
      </Routes>
    </Router>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
        </I18nextProvider>

  );
}
