import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from '../../../components/label';
import { fCurrency } from '../../../utils/formatNumber';
import REACT_APP_FRONT_APP_API_URL from './config_front'; // Import the configuration

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, image, price, description, priceSale, id } = product;

  const IMAGE_URL = 'https://placekitten.com/200/300';
  const imageUrl3 = `data:image/png;base64,${image}`;

  const handleLinkClick = () => {
    // Construct the URL with the car's ID as a query parameter
const url = `${REACT_APP_FRONT_APP_API_URL}/pay-en/${id}`;
    window.open(url, '_blank');
  };

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={name} src={imageUrl3} />
        {description && (
          <Label
            variant="filled"
            color={(description === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {description}
          </Label>
        )}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link color="inherit" underline="hover" onClick={handleLinkClick}>
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through',
                }}
              >
                {priceSale && fCurrency(priceSale)}
              </Typography>
              &nbsp;
              {fCurrency(price)} /day
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Card>
  );
}
