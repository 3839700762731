// ProtectedRoute.js
import { Route, Navigate } from 'react-router-dom';

const ProtectedRoute = ({ condition, redirectPath, ...props }) => {
  return condition ? (
    <Route {...props} />
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default ProtectedRoute;
