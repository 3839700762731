import { Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import ShopProductCardTFr from './sections/@dashboard/products/ShopProductCardTFr'
import ShopProductCardTar from './sections/@dashboard/products/ShopProductCardTar'
import ShopProductCardTEng from './sections/@dashboard/products/ShopProductCardTEng'

//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import TestAxios from './TestAxios';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import RentsPage from './pages/RentsPage';
import DashboardAppPage from './pages/DashboardAppPage';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'rents', element: <RentsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
        {
          path: '/pay',
          element: <ShopProductCardTFr />,
        },

        {
          path: '/pay-ar',
          element: <ShopProductCardTar />,
        },
        {
          path: '/pay-en/:id', // Include the ':id' parameter in the route
          element: <ShopProductCardTEng />,
        }
,
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
          path: 'test',
          element: <TestAxios />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
