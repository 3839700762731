import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ShopRentCard from './RentCard';
import API_BASE_URL from './config'; // Import the configuration


// Function to fetch data from the external web service
async function fetchRents() {
  const response = await axios.get(`${API_BASE_URL}/rent/1`);
  return response.data; // Axios automatically parses the JSON data, no need for .json()
}

// ----------------------------------------------------------------------

export default function RentList({ ...other }) {
  const [rents, setRents] = useState([]);

  useEffect(() => {
    // Fetch the rents from the external web service
    fetchRents()
      .then((data) => {
         if (Array.isArray(data)) {
         console.log("hi array")
           setRents(data);
         } else {
           console.error('Data is not an array:', data);
         }
       })
      .catch((error) => console.error('Error fetching rents:', error));
  }, []);
  return (
    <Grid container spacing={3} {...other}>
       <ShopRentCard rentals={rents} /> {/* Pass 'rents' as 'rentals' */}
     </Grid>
  );
}

RentList.propTypes = {
  // No need to specify PropTypes.array.isRequired here as the rents are fetched asynchronously
};