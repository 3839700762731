import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import {
  Card,
  Typography,
  Stack,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  AppBar,
  FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import imgLogo from './carlogowhite.jpeg'; // Adjust the path as needed
import API_BASE_URL from './config'; // Import the configuration

import CenteredForm from './CenteredForm'; // Import the CenteredForm component

const stripePromise = loadStripe('pk_test_6MGGWIzRIzoNtgfxgMeyxPqR00sYE0jQIQ');

ShopProductCardT.propTypes = {
  product: PropTypes.object,
};

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function ShopProductCardT({ product }) {

  const { id } = useParams();
  const [productData, setProductData] = useState({ description: '' });

  const formDataObj = new FormData();
  formDataObj.append('id', id);


  // Print the id parameter to the console
  console.log("ID Parameter:", id);

const [cardName, setCardName] = useState('');
const [cardCVV, setCardCVV] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rentalDate: null,
  });

const [totalPrice, setTotalPrice] = useState(0);


 const steps = [
    'Lets Go!',
     'Your car!',
    'Choose the mileage', // New step for "Choisissez le kilométrage" section
    'Choose your protection',
    'Options',
    'Check and reserver',
  ];  const [selectedKilometerOption, setSelectedKilometerOption] = useState(null); // To store the selected option
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState(null); // To store the selected option
  const [selectedOptionStep2, setSelectedOptionStep2] = useState(null); // To store the selected option for Step 2
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null); // Add this state
  const [cardNumber, setCardNumber] = useState(null); // Add this state
const [pickupDay, setPickupDay] = useState('');
const [pickupMonth, setPickupMonth] = useState('');
const [pickupYear, setPickupYear] = useState('');

const [returnDay, setReturnDay] = useState('');
const [returnMonth, setReturnMonth] = useState('');
const [returnYear, setReturnYear] = useState('');
const [pickupPlace, setPickupPlace] = useState('');
const [pickupTime, setPickupTime] = useState('');

const [pickupDate, setPickupDate] = useState(null);
const [returnDate, setReturnDate] = useState(null);
const [image3Url, setImage3Url] = useState(''); // Initialize with an empty string



  useEffect(() => {
    calculateTotalPrice();
  }, [pickupDate, returnDate, productData]);

  // Create a function to calculate the total price
  const calculateTotalPrice = () => {
    if (pickupDate && returnDate && productData[0].price) {
      // Calculate the number of days between pickup and return
      const startDate = new Date(pickupDate);
      const endDate = new Date(returnDate);
      const timeDifference = endDate - startDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      // Calculate the total price
const carPrice = parseInt(productData[0].price, 10); // The second argument specifies the base (10 for decimal).
      const calculatedPrice = daysDifference * carPrice;
console.log("calculatedPrice")
      setTotalPrice(calculatedPrice);
    }
  };


function generateDayOptions() {

}

function generateMonthOptions() {

}

function generateYearOptions() {

}
function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}



  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
const handlePayment = async (cardNumber, cardName) => {
  const stripe = await stripePromise;

  // Create a payment intent on your server
  const response = await fetch(`${API_BASE_URL}/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount: 1000 }), // Adjust the amount as needed
  });

  const { clientSecret } = await response.json();

  // Use Stripe.js to confirm the payment
  const result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: Elements.getElement(CardElement),
      billing_details: {
        name: cardName,
      },
    },
  });

  if (result.error) {
console.log("error")
 } else {
console.log("Payment ok")
  }
};

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append('id', 1211);
    formDataObj.append('subscriber_name', 'Name');
    formDataObj.append('email', 'Name');
    formDataObj.append('kilometrage', 'selectedKilometerOption');
    formDataObj.append('Assurance', 'selectedInsuranceOption');
    formDataObj.append('Options', 'selectedOptionStep2');
    formDataObj.append('start_date', 'Name');
    formDataObj.append('deleted_at', 'Name');
    formDataObj.append('active', true);
    formDataObj.append('loueur', 1);
    formDataObj.append('status', 'UNPAID');

    console.log(formDataObj.get('cardNumber'))
    console.log(formDataObj.get('Options'))

    axios.post(`${API_BASE_URL}/rent`, formDataObj)
      .then((response) => {
        console.log('Product added successfully!');
        console.log(response.data);

      })
      .catch((error) => {
        console.log('Failed to add product:', error);
      });
  /*    await handlePayment(cardNumber, cardName);

    // Reset form data and selected options if needed
    setFormData({
      name: '',
      email: '',
      subscriber_name: '',
      kilometrage: '',
      Assurance: '',
      Options: '',
      start_date: '',
      deleted_at: '',
      active: '',
      rentalDate: null,
    });

    setName('');
    setSelectedKilometerOption('null');
    setSelectedInsuranceOption('null');
    setSelectedOptionStep2('null');
    setCardNumber('null');
    */
  };

useEffect(() => {
  fetchProductData();
}, [id]);

const fetchProductData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/productById/2`);
    setProductData(response.data);

    // Check if productData contains the image URL
    if (!response.data[0] && response.data[0].image) {
      setImage3Url(`data:image/png;base64,${response.data[0].image}`);
    }
  } catch (error) {
    console.error('Error fetching product data:', error);
  }
};

  return (
    <Card style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
        {/* src={imgLogo} https://redacars.ma/wp-content/uploads/2022/01/LOGO-GRAND-FORMA-1-1.png */}

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
             src={imgLogo}
          alt="Logo"
          style={{ maxWidth: '20%', height: 'auto' }}
        />

          </Grid>
            <Grid item xs={12}>
              <CenteredForm onSubmit={handleFormSubmit}>
      <form onSubmit={handleFormSubmit}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

      <Stack spacing={2} sx={{ p: 3 }}>
{activeStep === 0 && (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-4">
               <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
      <label htmlFor="pickupPlace">Enter Pickup Place</label>
               <input
                 type="text"
                 id="pickupPlace"
                 className="form-control"
                 value={formData.pickupPlace}
                 onChange={(e) => setFormData({ ...formData, pickupPlace: e.target.value })}
               />
      </div>
      <div className="col-6 col-md-4">
        <div className="form-group">
         <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
          <label htmlFor="pickupDate">Pick-up Date</label>
          <input
            type="date"
            id="pickupDate"
            className="form-control"
            value={pickupDate || getCurrentDate()}
            onChange={(e) => setPickupDate(e.target.value)}
          />
        </div>
      </div>
      <div className="col-6 col-md-4">
        <div className="form-group">
                  <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
          <label htmlFor="pickupTime">Pickup Time</label>
          <input
            type="time"
            id="pickupTime"
            className="form-control"
            value={pickupTime}
            onChange={(e) => setPickupTime(e.target.value)}
          />
        </div>
      </div>
      <div className="col-6 col-md-4">
        <div className="form-group">
        <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
          <label htmlFor="returnDate">Return Date</label>
          <input
            type="date"
            id="returnDate"
            className="form-control"
            value={returnDate || getCurrentDate()}
            onChange={(e) => setReturnDate(e.target.value)}
          />
        </div>
      </div>
    </div>
  </div>
)}
{activeStep === 1 && productData && (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
  <img
       src={image3Url}
       alt="Product 3"
       style={{
         maxWidth: '30%',
         maxHeight: '30%',
         margin: '40px 0', // Add margin to push the image down
       }}
          />
    <Typography variant="h6">Total Price: ${totalPrice}</Typography>
    <Typography variant="body1">{productData[0].description}</Typography>
  </div>
)}

 {activeStep === 2 && (
            <>
              {/* "Choisissez le kilométrage" section */}
              <Typography variant="h6">Choisissez le kilométrage</Typography>
                       <FormControl component="fieldset">
                         <RadioGroup
                           aria-label="kilometerOptions"
                           name="kilometerOptions"
                           value={selectedKilometerOption}
                           onChange={(e) => setSelectedKilometerOption(e.target.value)}
                         >
                           <FormControlLabel
                             value="Longue distance-Kilomètres illimités+20,52 €"
                             control={<Radio />}
                             label="Longue distance - Kilomètres illimités + 20,52 €"
                           />
                           <FormControlLabel
                             value="Distance moyenne-Conduisez jusqu'à 510 km + 0,14 € par km supplémentaire"
                             control={<Radio />}
                             label="Distance moyenne-Conduisez jusqu'à 510 km + 0,14 € par km supplémentaire"
                           />
                         </RadioGroup>
                       </FormControl>
            </>
          )}
          {activeStep === 3 && (
            <>
              <Typography variant="h6">Assurance pertes et dommages</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="insuranceOptions"
                  name="insuranceOptions"
                  value={selectedInsuranceOption}
                  onChange={(e) => setSelectedInsuranceOption(e.target.value)}
                >
                  <FormControlLabel
                    value="9,96 € /jour"
                    control={<Radio />}
                    label="Frais de dépassement minimum - 3 00,00 € de responsabilité financière + protection pour le vol - 9,96 € /jour"
                  />
                  <FormControlLabel
                    value="5,53 € /jour"
                    control={<Radio />}
                    label="Frais de dépassement réduit - 7 00,00 € de responsabilité financière + protection pour le vol - 5,53 € /jour"
                  />
                  <FormControlLabel
                    value="Inclus"
                    control={<Radio />}
                    label="Frais de dépassement maximum - 14 00,00 € de franchise - Inclus"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
{activeStep === 4 && (
  <article>
    <Typography variant="h6">Choisissez vos options</Typography>
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="optionsStep2"
        name="optionsStep2"
        value={selectedOptionStep2}
        onChange={(e) => setSelectedOptionStep2(e.target.value)}
      >
        <FormControlLabel
          value="7.38"
          control={<Radio />}
          label="Conducteur supplémentaire - Partagez le volant avec un autre conducteur - 7,38 € /jour"
        />
        <FormControlLabel
          value="6.64"
          control={<Radio />}
          label={
            <span>
              Protection pneus et pare-brise - Zéro responsabilité financière pour les dégâts <br /> au pare-brise aux vitres et aux pneus
              6,64 € /jour
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
  </article>
)}
{activeStep === 5 && (
  <>
    <Typography variant="h6">Payment Options</Typography>
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="paymentOptions"
        name="paymentOptions"
        value={selectedPaymentOption}
        onChange={(e) => setSelectedPaymentOption(e.target.value)}
      >

        <FormControlLabel
          value="option2"
          control={<Radio />}
          label="Save money by paying now."
        />
        <FormControlLabel
          value="option3"
          control={<Radio />}
          label="Stay flexible by paying later. Pay upon pickup."
        />
      </RadioGroup>
    </FormControl>

    {selectedPaymentOption !== "option3" && (
      <>
        <Typography variant="h6">Driver Information</Typography>
        <TextField
          label="First Name"
          variant="outlined"
          value={formData.driverName}
          onChange={(e) => setFormData({ ...formData, driverName: e.target.value })}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          value={formData.driverLastName}
          onChange={(e) => setFormData({ ...formData, driverLastName: e.target.value })}
        />
        <TextField
          label="Email"
          variant="outlined"
          value={formData.driverEmail}
          onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })}
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          value={formData.driverPhone}
          onChange={(e) => setFormData({ ...formData, driverPhone: e.target.value })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isOver24}
              onChange={(e) => setFormData({ ...formData, isOver24: e.target.checked })}
            />
          }
          label="Are you over 23 years old?"
        />

        <Typography variant="h6">Payment</Typography>
        <Elements stripe={stripePromise}> {/* Wrap the part containing CardElement */}
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </Elements>
      </>
    )}
  </>
)}
          <Stack direction="row" justifyContent="space-between">
            {activeStep > 0 && (
              <Button variant="contained" color="secondary" onClick={handleBack}>
                Back
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (

<Button variant="contained" color="primary" onClick={handleFormSubmit}>
                           Payer maintenant
              </Button>

            )}
          </Stack>
        </Stack>
      </form>
      </CenteredForm>
 </Grid>
    </Grid>
    </Card>
  );
}
