 import { useState } from 'react';
import { Button, TextField, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel'
import axios from 'axios';
import API_BASE_URL from './config'; // Import the configuration

export default function NewProductForm({ onCancel }) {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productConditions, setProductConditions] = useState('');
  const [productImage, setProductImage] = useState(null);

const handleImageChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    // Read the selected image as a base64 string
    const reader = new FileReader();
    reader.onloadend = () => {
      // Get the base64 string without the prefix
      const base64String = reader.result.split(',')[1];
      setProductImage(base64String); // Set the image without the prefix
    };
    reader.readAsDataURL(file);
  }
};

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();
        formData.append('id', "12");
    formData.append('productName', productName);
    formData.append('description', productDescription);
    formData.append('price', productPrice);
    formData.append('loueurid', "1");
    formData.append('deletedAt', "true");
        console.log(formData)


    // Append the base64 string of the image (if available) to the FormData
    if (productImage) {
      formData.append('imagestring', productImage);
    }
formData.forEach((value, key) => {
  console.log(`${key}: ${value}`);
});  // Make a POST request to the backend using Axios

    axios.post(`${API_BASE_URL}/product`, formData)
      .then((response) => {
        // Handle the response from the server (if needed)
        console.log('Product added successfully!');
        console.log(response.data);

        // Clear the form inputs after successful submission
        setProductName('');
        setProductDescription('');
        setProductPrice('');
        setProductConditions('');
        setProductImage(null);

        // Close the form after successful submission
        onCancel();
      })
      .catch((error) => {
        // Handle any errors that occur during the POST request
        console.error('Failed to add product:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <InputLabel htmlFor='Car name' sx={{ mb: 2, fontSize: '.75rem', maxWidth: 'max-content' }}>
                Car name
              </InputLabel>
        <TextField
          variant="outlined"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          required
          fullWidth
        />
        <InputLabel htmlFor='Car description' sx={{ mb: 2, fontSize: '.75rem', maxWidth: 'max-content' }}>
                        Car description
                      </InputLabel>
        <TextField
          variant="outlined"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          required
          fullWidth
          multiline
          rows={4}
        />
          <InputLabel htmlFor='Your rental conditions' sx={{ mb: 2, fontSize: '.75rem', maxWidth: 'max-content' }}>
                                Your rental conditions
                              </InputLabel>
           <TextField

                          variant="outlined"
                          value={productConditions}
                          onChange={(e) => setProductConditions(e.target.value)}
                          required
                          fullWidth
                          multiline
                           rows={2}
                        />
                               <InputLabel htmlFor='Rental price' sx={{ mb: 2, fontSize: '.75rem', maxWidth: 'max-content' }}>
                                                        Rental price
                                                      </InputLabel>
        <TextField
          variant="outlined"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          required
          fullWidth
        />
    <InputLabel htmlFor='Your car image' sx={{ mb: 2, fontSize: '.75rem', maxWidth: 'max-content' }}>
Your car image                                                      </InputLabel>        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          required
          style={{ marginBottom: '16px' }}
        />
        <Button type="submit" variant="contained" color="primary">
          Add Car
        </Button>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </form>
  );
}