import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from './config'; // Import the configuration

const MyComponent = () => {
  const [item, setItem] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/getAll/1`); // Replace '1' with the desired itemId
        setItem(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {item ? (
        <div>
          <h1>{item.name}</h1>
          <p>{item.description}</p>
        </div>
      ) : (
        <p>Loading item...</p>
      )}
    </div>
  );
};

export default MyComponent;
