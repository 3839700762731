import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  Typography,
  Stack,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  AppBar,
  Toolbar,
  FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Grid
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Choose the appropriate date adapter for your project

import { styled, createTheme, ThemeProvider} from '@mui/material/styles';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { DatePicker,LocalizationProvider } from '@mui/lab';
import imgLogo from './img_6.png'; // Adjust the path as needed

import CenteredForm from './CenteredForm'; // Import the CenteredForm component
import API_BASE_URL from './config'; // Import the configuration

const stripePromise = loadStripe('pk_test_6MGGWIzRIzoNtgfxgMeyxPqR00sYE0jQIQ');

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
const Header = styled(AppBar)({
  backgroundColor: '#2196F3', // Customize the color
  marginBottom: '16px',
});
const HeaderTitle = styled(Typography)({
  flexGrow: 1,
});

const RadioLabel = styled(Typography)({
  marginBottom: '8px',
});

const SubmitButton = styled(Button)({
  marginTop: '16px',
});

ShopProductCardT.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCardT({ product }) {
const [Name, setName] = useState('');
const [Prenom, setPrenom] = useState('');
const [Email, setEmail] = useState('');
const [cardName, setCardName] = useState('');
const [cardCVV, setCardCVV] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rentalDate: null,
  });

const steps = [
  'معلومات السيارة والسائق',
  'اختر عدد الكيلومترات المطلوبة',
  'اختر خيارات التأمين',
  'اختر الخيارات الإضافية',
  'التحقق والدفع',
];


  const [selectedKilometerOption, setSelectedKilometerOption] = useState(null); // To store the selected option
  const [selectedInsuranceOption, setSelectedInsuranceOption] = useState(null); // To store the selected option
  const [selectedOptionStep2, setSelectedOptionStep2] = useState(null); // To store the selected option for Step 2
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null); // Add this state
  const [cardNumber, setCardNumber] = useState(null); // Add this state
const [pickupDay, setPickupDay] = useState('');
const [pickupMonth, setPickupMonth] = useState('');
const [pickupYear, setPickupYear] = useState('');

const [returnDay, setReturnDay] = useState('');
const [returnMonth, setReturnMonth] = useState('');
const [returnYear, setReturnYear] = useState('');

function generateDayOptions() {

}

function generateMonthOptions() {

}

function generateYearOptions() {

}




  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
const handlePayment = async (cardNumber, cardName) => {
  const stripe = await stripePromise;

  // Create a payment intent on your server
  const response = await fetch(`${API_BASE_URL}/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount: 1000 }), // Adjust the amount as needed
  });

  const { clientSecret } = await response.json();

  // Use Stripe.js to confirm the payment
  const result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: Elements.getElement(CardElement),
      billing_details: {
        name: cardName,
      },
    },
  });

  if (result.error) {
console.log("error")
 } else {
console.log("Payment ok")
  }
};

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append('id', 1211);
    formDataObj.append('subscriber_name', Name);
    formDataObj.append('email', Name);
    formDataObj.append('kilometrage', selectedKilometerOption);
    formDataObj.append('Assurance', selectedInsuranceOption);
    formDataObj.append('Options', selectedOptionStep2);
    formDataObj.append('start_date', Name);
    formDataObj.append('deleted_at', "-");
    formDataObj.append('active', true);
    formDataObj.append('cardNumber', cardNumber);

    console.log(formDataObj.get('cardNumber'))
    console.log(formDataObj.get('Options'))

    axios.post(`${API_BASE_URL}/rent`, formDataObj)
      .then((response) => {
        console.log('Product added successfully!');
        console.log(response.data);

      })
      .catch((error) => {
        console.log('Failed to add product:', error);
      });
      await handlePayment(cardNumber, cardName);

    // Reset form data and selected options if needed
    setFormData({
      name: '',
      email: '',
      subscriber_name: '',
      kilometrage: '',
      Assurance: '',
      Options: '',
      start_date: '',
      deleted_at: '',
      active: '',
      rentalDate: null,
    });

    setName('');
    setSelectedKilometerOption('null');
    setSelectedInsuranceOption('null');
    setSelectedOptionStep2('null');
    setCardNumber('null');
  };

const theme = createTheme({
  direction: 'rtl',
});

return (
 <ThemeProvider theme={theme}>
  <Card style={{ marginTop: '20px' }}>
    <Grid container spacing={2}>
      {/* src={imgLogo} https://redacars.ma/wp-content/uploads/2022/01/LOGO-GRAND-FORMA-1-1.png */}

      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       <img src="https://goldenbeachrentacar.com/wp-content/webp-express/webp-images/uploads/2020/02/golden-beach-logo.png.webp"
                 alt="Logo"
                 style={{ maxWidth: '40%', height: 'auto' }}
               />
      </Grid>
      <Grid item xs={12}>
        <CenteredForm onSubmit={handleFormSubmit}>
          <form onSubmit={handleFormSubmit}>
<Grid container direction="row-reverse"> {/* Set the container direction to "row-reverse" */}
  <Stepper activeStep={activeStep} alternativeLabel>
    {steps.map((label, index) => (
      <Step key={label} style={{ marginBottom: index !== steps.length - 1 ? '16px' : '0' }}>
        <StepLabel
          dir="rtl" // Set direction to right-to-left (Arabic style)
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'right', // Align the text to the right
          }}
        >
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
</Grid>
            <Stack spacing={2} sx={{ p: 3 }}>
{activeStep === 0 && (
  <>
    <Typography variant="h6" dir="rtl">معلوماتك الشخصية</Typography>

   <TextField
     label="الاسم"
     variant="outlined"
     value={Name}
     onChange={(e) => setName(e.target.value)}
     InputLabelProps={{ dir: "rtl", style: { textAlign: 'right' } }}
     inputProps={{ dir: "rtl", style: { textAlign: 'right' } }}
   />


    <TextField
      label="اللقب"
      variant="outlined"
      value={Prenom}
      onChange={(e) => setPrenom(e.target.value)}
      InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"
    />

    <TextField
      label="عنوان البريد الإلكتروني"
      variant="outlined"
      value={Email}
      onChange={(e) => setEmail(e.target.value)}
      InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"
    />
  </>
)}
              {activeStep === 1 && (
                <>
                  {/* قسم "اختر عدد الكيلومترات" */}
                  <Typography variant="h6" dir="rtl">اختر عدد الكيلومترات</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="خيارات الكيلومترات"
                      name="خيارات الكيلومترات"
                      value={selectedKilometerOption}
                      onChange={(e) => setSelectedKilometerOption(e.target.value)}
                    >
                      <FormControlLabel
                        value="Longue distance-Kilomètres illimités+20,52 €"
                        control={<Radio />}
                        label="مسافة طويلة - كيلومترات غير محدودة"
                        dir="rtl"
                      />
                      <FormControlLabel
                        value="Distance moyenne-Conduisez jusqu'à 510 km + 0,14 € par km supplémentaire"
                        control={<Radio />}
                        label="مسافة متوسطة - قيادة حتى 510 كم "
                                                dir="rtl"

                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <Typography variant="h6" dir="rtl">تأمين الخسائر والأضرار</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="خيارات التأمين"
                      name="خيارات التأمين"
                      value={selectedInsuranceOption}
                      onChange={(e) => setSelectedInsuranceOption(e.target.value)}
                        dir="rtl"
                    >
                      <FormControlLabel
                        value="9,96 $ /jour"
                        control={<Radio />}
                        label="تكلفة التجاوز الدنيا - مسؤولية مالية دنيا + حماية من السرقة - 9,96$ /يوم"
                        dir="rtl"
                      />
                      <FormControlLabel
                        value="5,53 € /jour"
                        control={<Radio />}
                        label="تكلفة التجاوز المخفضة - مسؤولية مالية + حماية من السرقة - 5,53$ /يوم"
                          dir="rtl"
                      />
                      <FormControlLabel
                        value="مشمولة"
                        control={<Radio />}
                        label="تكلفة التجاوز القصوى - من الإعفاء الذاتي - مشمولة"
                          dir="rtl"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <Typography variant="h6" dir="rtl">اختر الخيارات الخاصة بك</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="optionsStep2"
                      name="optionsStep2"
                      value={selectedOptionStep2}
                      onChange={(e) => setSelectedOptionStep2(e.target.value)}
                        dir="rtl"
                    >
                      <FormControlLabel
                        value="7,38 € /jour"
                        control={<Radio />}
                        label="سائق إضافي - شارك في القيادة مع سائق آخر "
                          dir="rtl"
                      />
                      <FormControlLabel
                        value= "6.64 دولار / يوم"
                        control={<Radio />}
                        label={
                          <span>
                            حماية الإطارات والزجاج الأمامي - لا مسؤولية مالية للأضرار <br /> للزجاج الأمامي والنوافذ والإطارات
                            6,64 $ /يوم
                          </span>
                        }
                          dir="rtl"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <Typography variant="h6" dir="rtl">خيارات الدفع</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="paymentOptions"
                      name="خيارات الدفع"
                      value={selectedPaymentOption}
                      onChange={(e) => setSelectedPaymentOption(e.target.value)}
                        dir="rtl"
                    >
                      <FormControlLabel
                        value="option2"
                        control={<Radio />}
                        label="وفّر المال عند الدفع الآن."
                          dir="rtl"
                      />
                      <FormControlLabel
                        value="option3"
                        control={<Radio />}
                        label="ادفع لاحقًا عند الاستلام."
                          dir="rtl"
                      />
                    </RadioGroup>
                  </FormControl>

                  {selectedPaymentOption !== "option3" && (
                    <>
                      <Typography variant="h6" dir="rtl">معلومات السائق</Typography>
                      <TextField
                        label="الاسم"
                        variant="outlined"
                        value={formData.driverName}
                        onChange={(e) => setFormData({ ...formData, driverName: e.target.value })}
                        InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"
                      />
                      <TextField
                        label="اسم العائلة"
                        variant="outlined"
                        value={formData.driverLastName}
                        onChange={(e) => setFormData({ ...formData, driverLastName: e.target.value })}
                        InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"

                      />
                      <TextField
                        label="البريد الإلكتروني"
                        variant="outlined"
                        value={formData.driverEmail}
                        onChange={(e) => setFormData({ ...formData, driverEmail: e.target.value })}
                        InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"

                      />
                      <TextField
                        label="رقم الهاتف"
                        variant="outlined"
                        value={formData.driverPhone}
                        onChange={(e) => setFormData({ ...formData, driverPhone: e.target.value })}
                        InputLabelProps={{ dir: "rtl" }} // Set dir to "rtl"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.isOver24}
                            onChange={(e) => setFormData({ ...formData, isOver24: e.target.checked })}
                          />
                        }
                        label="هل أنت أكبر من 23 عامًا؟"
                          dir="rtl"
                      />

                      <Typography variant="h6" dir="rtl">الدفع</Typography>
                      <Elements stripe={stripePromise}> {/* قم بلف الجزء الذي يحتوي على CardElement */}
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: '16px',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }}
                        />
                      </Elements>
                    </>
                  )}
                </>
              )}
              <Stack direction="row" justifyContent="space-between">
              {activeStep < steps.length - 1 && (
                <Button variant="contained" color="primary" onClick={handleNext}>
                   التالي
                  </Button>
                    )}
                {activeStep > 0 && (
                  <Button variant="contained" color="secondary" onClick={handleBack}>
                    السابق
                  </Button>
                )}

                {activeStep === steps.length - 1 && (
                  <Elements stripe={stripePromise}>
                    <SubmitButton type="submit" variant="contained" color="primary">
                      قم بالدفع الآن
                    </SubmitButton>
                  </Elements>
                )}
              </Stack>
            </Stack>
          </form>
        </CenteredForm>
      </Grid>
    </Grid>
  </Card>
      </ThemeProvider>
);
}
