import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Container, Stack, Typography, Button } from '@mui/material';
import axios from 'axios';
import Iconify from '../components/iconify';
import NewProductForm from './NewProductForm';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import PRODUCTS from '../_mock/products';


export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
      const { t } = useTranslation();

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleShowNewProductForm = () => {
    setIsFormVisible(true);
  };

  const handleHideNewProductForm = () => {
    setIsFormVisible(false);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Your Cars | Payease </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
      <p>{t('Your cars')}</p>
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleShowNewProductForm}>
            Add a new car
          </Button>
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>

            <ProductSort />
          </Stack>
        </Stack>

        {/* Display the form if isFormVisible is true, else show the product list */}
        {isFormVisible ? (
          <NewProductForm onCancel={handleHideNewProductForm} />
        ) : (
          <>
            <ProductList products={PRODUCTS} />
            <ProductCartWidget />
          </>
        )}
      </Container>
    </>
  );
}