import React from 'react';
import { Card, styled } from '@mui/material';

const CenteredContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center', // Center vertically
  justifyContent: 'center', // Center horizontally
  padding: '16px', // Add some padding for spacing
});
export default function CenteredForm({ children }) {
  return (
    <CenteredContainer>
      <Card>{children}</Card>
    </CenteredContainer>
  );
}