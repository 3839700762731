import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Card, Link, Typography, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from '../../../components/label';
import { fCurrency } from '../../../utils/formatNumber';
import REACT_APP_FRONT_APP_API_URL from './config_front'; // Import the configuration

const StickyTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: #fff; // Customize the background color as needed
`;

ShopRentCard.propTypes = {
  rentals: PropTypes.arrayOf(PropTypes.object),
};

export default function ShopRentCard({ rentals }) {
  const handleLinkClick = (id) => {
    // Construct the URL with the car's ID as a query parameter
    const url = `${REACT_APP_FRONT_APP_API_URL}/pay-en/${id}`;
    window.open(url, '_blank');
  };

  // Check if rentals is defined and is an array
  if (!rentals || !Array.isArray(rentals)) {
    return null; // or render an appropriate message
  }

  return (
          <Table sx={{ pt: '100%', position: 'relative' }}>
          <StickyTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Kilometrage</TableCell>
              <TableCell>Assurance</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>
            {rentals.map((rent) => (
              <TableRow key={rent.id}>
                <TableCell>
                  {rent.id && (
                    <Label
                      variant="filled"
                      color={(rent.name === 'sale' && 'error') || 'info'}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      {rent.name}
                    </Label>
                  )}
                </TableCell>
                <TableCell>{rent.kilometrage}</TableCell>
                <TableCell>
                  <Link color="inherit" underline="hover" onClick={() => handleLinkClick(rent.id)}>
                    <Typography variant="subtitle1">
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          color: 'text.disabled'
                        }}
                      >
                        {rent.assurance}
                      </Typography>
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>{rent.options}</TableCell>
                <TableCell>
                  {/* Conditionally set the color based on the "status" value */}
                  <Typography
                    component="span"
                    variant="body1"
                   sx={{
                      color: rent.status === 'PAID' ? 'green' : 'red',
                      fontWeight: 'bold', // Add this line to make the text bold
                    }}
                  >
                    {rent.status}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
  );
}
